.ModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.66);
  min-height: -webkit-fill-available;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 345px;
  max-height: 90vh;
  border-radius: 30px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow: scroll;
}

.TicketContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 285px;
  width: 100%;
  margin: 0 15px 30px;
  background-color: #e9f2fe;
  padding: 0 15px 5px;
  position: relative;
}

.Wave {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f2fe;
  position: relative;
  min-width: 285px;
  margin: 30px 15px 0;
  padding: 30px 15px 0;
}

.Wave::before,
.Wave::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat-x;
}
.Wave::before {
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(
    circle at 10px -5px,
    transparent 12px,
    white 13px
  );
}

.WaveTop {
  background-color: #e9f2fe;
}

.WaveTop::before,
.WaveTop::after {
  bottom: auto;
  top: 0;
}
.WaveTop::before {
  background-image: radial-gradient(
    circle at 10px 15px,
    transparent 12px,
    white 13px
  );
}

.TicketContainer::before,
.TicketContainer::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat-x;
}

.TicketContainer::before {
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(
    circle at 10px -5px,
    transparent 12px,
    white 13px
  );
}

.Item {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.Title {
  margin-top: 30px;
  font-family: 'ModernEraBold';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.SubTitle {
  margin: 10px 15px 0;
  font-family: 'ModernEra';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d7278;
}

.FormSubmitButton {
  outline: none;
  margin-top: 30px;
  min-width: 156px;
  margin-bottom: 200px;
}

.SubmitButton {
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.Key {
  font-family: 'ModernEraBold';
  margin: 0;
}

.Value {
  font-family: 'ModernEra';
  margin: 0;
}

.ToCorrect {
  margin: 30px;
  font-family: 'ModernEraBold';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0752f9;
  cursor: pointer;
  outline: none;
}

@media (max-width: 350px) {
  .Wrapper {
    max-width: 290px;
  }

  .TicketContainer {
    max-width: 230px;
  }

  .Wave {
    min-width: 230px;
  }
}

@media (min-width: 700px) {
}
